<template>
  <div class="landing-main-container">
    <img class="img-landing-logo" src="@/assets/images/logo.png" alt="">
  </div>
</template>
<script>
export default {
  name: 'Landing'
}
</script>
<style lang="scss" scoped>
.landing-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.img-landing-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 16%;
}
</style>
